// extracted by mini-css-extract-plugin
export var hero_image = "index-module--hero_image--Vqse+";
export var hero_button = "index-module--hero_button--A6jh8";
export var slogan = "index-module--slogan--W5j7y";
export var hero_text = "index-module--hero_text--wnWgj";
export var large_arrow = "index-module--large_arrow--Z0yut";
export var speerpunten_title = "index-module--speerpunten_title--LM9c2";
export var more_block = "index-module--more_block--ELofQ";
export var active = "index-module--active--Eoge+";
export var personImageContainer = "index-module--personImageContainer--zbjaw";
export var homeImageContentMensen = "index-module--home-image-content-mensen--RXilR";