import React from 'react'
import { graphql } from 'gatsby'
import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import Layout from '../components/Layout'
import './index.styles.css'
import '../../static/styles/fontawesome-5.15.2/css/fontawesome.min.css'
import { GatsbyImage } from "gatsby-plugin-image";
import Collapsible from 'react-collapsible';
import BlogArticleList from "../components/BlogArticleList"
import LijsterCarousel from "../components/LijsterCarousel"
import Helmet from 'react-helmet'

const style = require("./index.module.css");

const IndexPage = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout>
      <Helmet>
        <title>STIP - Politieke partij Delft</title>
        <meta name="description" content={intl.formatMessage({ id: "STIP is een politieke partij in de gemeente Delft. STIP zet zich in voor een duurzaam, leefbaar en cultureel Delft." })} />
        <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://www.stipdelft.nl",
              "logo": "https://www.stipdelft.nl/images/logo/stip_logo_2021.svg"
            }
      `}
        </script>
      </Helmet>
      <div className={style.hero_image}>
        <div className="row">
          <div className={style.hero_text}>
            <div className="columns">
              <div className="large-4 medium-7 small-12">
                <p className={style.slogan}>
                  {
                    // intl.formatMessage({ id: "Stem 14,15 & 16 maart STIP!" })
                  }</p> <p style={{ "font-size": "1.6em" }}><br />{intl.formatMessage({ id: "korte_boodschap_frontpage_hero" })}</p>
                <a className={style.hero_button_link} href={intl.formatMessage({ id: "verkiezingsprogramma_link" })}><button className={style.hero_button}>{intl.formatMessage({ id: "Onze plannen" })}</button></a><span> </span>
                <Link to="/speerpunten/successen"><button className={style.hero_button}>{intl.formatMessage({ id: "Onze successen" })}</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        data.speerpunten &&
        <>
          <div className="row">
            <div className="columns large-12 large-centered">
              <p className={style.speerpunten_title}>{intl.formatMessage({ id: "Standpunten" })}</p>
              {intl.formatMessage({ id: "stukje_frontpage_onder_speerpunten" })}<br />
            </div>
          </div>
          <div className="row">
            {data.speerpunten.edges.map(function({ node }, index) {
              const speerpunt = node;
              const description = JSON.parse(speerpunt.description.raw).content[0].content[0].value;
              return (
                <>
                  <div className="columns small-11 small-centered padded-block show-for-small-only">
                    <Collapsible className="home-image-title" trigger={[speerpunt.title, "↴"].join(" ")}>
                      <div className="home-image-content-text content-inner small-12">
                        {description}
                      </div>
                    </Collapsible>
                  </div>
                  <div className="columns large-6 medium-12 small-12 hide-for-small">
                    <h2>{speerpunt.title}</h2>
                    <div className="columns large-8 medium-9 small-12">
                      <div>
                        {description}
                      </div>
                    </div>
                    <div className="columns large-4 medium-3 hide-for-small">
                      <GatsbyImage image={speerpunt.icon.gatsbyImageData} alt="" />
                    </div>
                  </div>
                </>
              );
            })}
            <a href={intl.formatMessage({ id: "verkiezingsprogramma_link" })}>
              <div className="columns large-6">
                <div className={style.more_block}>
                  <h2>{intl.formatMessage({ id: "Bekijk ons hele verkiezings­programma" })}</h2>
                  {intl.formatMessage({ id: "Lees meer over onze plannen voor 2022-2026" })}
                  <p className={style.large_arrow}>⟶</p>
                </div>
              </div>
            </a>
          </div>
        </>
      }

      <br />
      {/* <div className="row"> */}
      {/*   <video controls width="100%" controlslist="nodownload" poster={intl.formatMessage({ id: "campagne_video_poster" })}> */}
      {/*     <source src={intl.formatMessage({ id: "campagne_video_link" })} type="video/mp4" /> */}
      {/*   </video> */}
      {/* </div> */}

      {/*data.lijsters && 
        <div className="row">
            <br />
            <h2 className="nieuwstitel">
                {intl.formatMessage({id:"Onze mensen"})}
            </h2>
            <LijsterCarousel lijsters={data.lijsters} amount={10} />
            <Link className="blog-pagination right" to="/mensen/lijst/">
                {intl.formatMessage({id:"Bekijk de hele lijst"})} →
            </Link>
        </div>*/
      }

      {
        data.articles &&
        <div className="row blog-helper-listing" id="home-news">
          <h2 className="nieuwstitel">
            {intl.formatMessage({ id: "Nieuws" })}
          </h2>
          <div className="large-12 columns">
            <BlogArticleList articles={data.articles} />
          </div>
        </div>
      }

      <div className="row">
        <div className="large-12 columns">
          <div className="pagination home-more-news">
            <Link className="blog-pagination right" to="/actueel#6">
              {intl.formatMessage({ id: "Meer nieuws" })} →</Link>
          </div>
        </div>
      </div>

    </Layout >
  )
}

export default IndexPage

export const query = graphql`
  query($node_locale: String) {
    speerpunten: allContentfulSpeerpunt(
        sort: {fields: order, order: ASC}
        filter: {node_locale: {eq: $node_locale}}
    ) {
        edges {
            node {
                title
                description {
                    raw
                }
                icon {
                  gatsbyImageData(formats: WEBP)
                }
            }
        }
    }
    lijsters: contentfulKandidatenlijst(node_locale: {eq: $node_locale}) {
        kandidaten {
          name
          slug
          node_locale
          lijstfoto {
            gatsbyImageData(quality: 80, height: 200)
          }
        }
    }
    articles: allContentfulBlogartikel(
      sort: {  fields: date order: DESC }
      limit: 6
      filter: {node_locale: {eq: $node_locale}}
    ) {
      edges {
        node {
          id
          migrationId
          slug
          date(formatString: "DD - MM - YYYY")
          title
          lead {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            gatsbyImageData(formats: WEBP, width: 500)
          }
        }
      }
    }
  }
`
